import { PoliciesAndTerms, AccountManagement } from '.';

const routes = [
  {
    path: '/cx/policiesandterms',
    element: <PoliciesAndTerms />,
    title: 'Policies and Terms'
  },
  {
    path: '/cx/account-management',
    element: <AccountManagement />,
    title: 'Account Management'
  }
];

export default routes;
