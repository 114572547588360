import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useDropdown } from '@/hooks';

import Menu from './components/Menu';
import MenuCX from './components/MenuCX';
import ButtonIcon from '../ButtonIcon';
import NotificationIcon from './NotificationIcon';

import logoSmall from '@/assets/images/logo.png';

import './styles.scss';

const Navbar = () => {
  const isAuthenticated = !!Cookies.get('token');

  const { isOpen, ref, close, toggle } = useDropdown();

  const navigate = useNavigate();
  const location = useLocation();

  const isCxCenter = location.pathname.startsWith('/cx');

  const handleNotifsClick = () => {
    close();
    navigate('/notifications/all');
  };

  return (
    <nav className='navbar' ref={ref}>
      <nav className='logo-dropdown-toggle'>
        <Link to='/contact-management' className='logo-link' onClick={close}>
          <img src={logoSmall} />
        </Link>
        <div className='right'>
          {isAuthenticated && <NotificationIcon onClick={handleNotifsClick} />}
          <ButtonIcon
            icon={isOpen ? 'close' : 'menu'}
            color='deep-sapphire'
            className='dropdown-toggle'
            onClick={toggle}
          />
        </div>
      </nav>
      {isCxCenter ? (
        <MenuCX isOpen={isOpen} close={close} />
      ) : (
        <Menu isOpen={isOpen} close={close} />
      )}
    </nav>
  );
};

export default Navbar;
