import React, { lazy } from 'react';

const Home = lazy(() => import('.'));

const routes = [
  {
    path: '/',
    element: <Home />,
    title: 'Home'
  },
  {
    path: '/cx',
    element: <Home />,
    title: 'Home'
  }
];

export default routes;
