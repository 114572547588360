import clsx from 'clsx';
import MenuGroup from './MenuGroup';

import { CUSTOMER_EXPERIENCE } from '../fixtures/links';

import '../styles/Menu.scss';

const Menu = ({ isOpen, close }) => {
  const menuClassNames = clsx(
    'dropdown-menu',
    'menu',
    isOpen ? '-shown' : '-hidden'
  );

  return (
    <nav className={menuClassNames}>
      <MenuGroup links={CUSTOMER_EXPERIENCE} onClick={close} title='Customer Experience Center' />
      <div className='contact'>
        <p>Contact us at <span>cx@synthnode.io</span></p>
      </div>
    </nav>
  );
};

export default Menu;
